var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"chats",attrs:{"id":"chat"}},[(_vm.loading)?_c('div',{staticClass:"d-flex justify-content-center align-items-center"},[_c('b-spinner',{attrs:{"label":"Loading..."}})],1):_vm._e(),_vm._l((_vm.formattedChatData.formattedChatLog),function(msgGrp,index){return _c('div',{key:msgGrp.senderId + String(index),staticClass:"chat",class:{ 'chat-left': msgGrp.own == 0 }},[_c('div',{staticClass:"chat-avatar"},[_c('b-avatar',{staticClass:"avatar-border-2 box-shadow-1",attrs:{"size":"36","src":msgGrp.senderAvatar}})],1),_c('div',{staticClass:"chat-body"},[_vm._l((msgGrp.messages),function(msgData){return [_c('b-overlay',{key:msgData.time,ref:msgData.message_id,refInFor:true,staticClass:"chat-content d-flex align-items-start flex-column text-break",class:{
            'text-light': _vm.skin == 'dark',
            'text-dark':
              (_vm.skin == 'light' ||
                _vm.skin == 'semi-dark' ||
                _vm.skin == 'bordered') &&
              msgGrp.own == 0,
            'pt-2': msgGrp.own == 1,
          },staticStyle:{"width":"100%"},style:(((msgData.error == true
              ? 'background-image: linear-gradient(80deg, rgb(255 55 55), rgb(255 106 106)) !important; cursor:pointer;'
              : '') + " border-radius: " + (msgGrp.own == 0 ? '3px 10px 10px 10px' : '10px 3px 10px 10px'))),attrs:{"show":msgData.was_sent == false && !msgData.error,"blur":"0","opacity":0.5,"variant":_vm.skin == 'dark' ? 'dark' : 'light',"rounded":"sm","overlay-tag":"div","id":'msg_' + msgData.message_id},on:{"click":function($event){msgData.error == true ? _vm.sendMessageReply(msgData) : null}}},[(msgData.senderId != _vm.currentUser.user_id)?_c('div',{staticClass:"d-flex justify-content-between align-items-center mt-1",class:{ 'mx-1': _vm.currentBreakPoint != 'xs' },staticStyle:{"width":"96%"}},[_c('span',[_c('h5',[_vm._v(_vm._s(msgData.senderName))])]),_c('div',{staticClass:"dropdown m-0"},[_c('b-dropdown',{attrs:{"variant":"link","no-caret":"","toggle-class":"p-0 ","right":""},scopedSlots:_vm._u([{key:"button-content",fn:function(){return [_c('feather-icon',{staticClass:"align-middle m-0 text-dark",attrs:{"icon":"ChevronDownIcon"}})]},proxy:true}],null,true)},[_c('b-dropdown-item',{on:{"click":function($event){return _vm.replyMessage(msgData)}}},[_vm._v(" Reply ")])],1)],1)]):_vm._e(),(msgData.subjectresp && msgData.subjectresp != 'null')?_c('div',{staticClass:"py-1 px-2",class:{ 'mx-1': _vm.currentBreakPoint != 'xs' },staticStyle:{"width":"96%","border-radius":"5px","margin-top":"10px","margin-bottom":"10px"},style:({
              background:
                (_vm.skin == 'light' ||
                  _vm.skin == 'semi-dark' ||
                  _vm.skin == 'bordered') &&
                msgGrp.own == 0
                  ? 'rgb(142 114 248 / 25%)'
                  : 'rgb(255 255 255 / 25%)',
            })},[_c('strong',[_vm._v(_vm._s(msgData.subjectresp))]),(msgData.contentresp)?_c('div',{staticClass:"w-100 mt-1",class:{
                'text-light': _vm.skin == 'dark',
                'text-dark':
                  (_vm.skin == 'light' ||
                    _vm.skin == 'semi-dark' ||
                    _vm.skin == 'bordered') &&
                  msgGrp.own == 0,
              }},[_c('span',[_c('p',{domProps:{"innerHTML":_vm._s(msgData.contentresp)}})])]):_vm._e()]):_vm._e(),(msgData.subjectresp && msgData.subjectresp != 'null')?_c('hr',{staticClass:"border-hr",class:{ 'mx-1': _vm.currentBreakPoint != 'xs' },staticStyle:{"width":"96%"}}):_vm._e(),_c('div',{class:{ 'mx-1': _vm.currentBreakPoint != 'xs' }},[_c('span',{staticClass:"mb-1"},[_c('strong',{ref:"refSubject",refInFor:true,domProps:{"innerHTML":_vm._s(msgData.subject)}})]),_c('p',{ref:"refMessage",refInFor:true,staticClass:"mt-1",domProps:{"innerHTML":_vm._s(msgData.msg)}})]),_c('b-row',{staticClass:"w-100 mt-1",staticStyle:{"padding-left":"14px"}},_vm._l((msgData.files),function(file,index){return _c('b-col',{key:index,attrs:{"cols":"12","md":"4","lg":"3"}},[_c('a',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover",value:(file.name || file),expression:"file.name || file",modifiers:{"hover":true}}],staticClass:"btn btn-sm btn-warning-light text-light w-100 mb-1 d-flex align-items-center justify-content-center",attrs:{"href":msgData.route_temp[index],"download":"","target":"_blank"}},[_c('span',{staticClass:"mr-50",staticStyle:{"font-weight":"800","max-width":"10ch","text-overflow":"ellipsis","overflow":"hidden","white-space":"nowrap","display":"inline-block"}},[_vm._v(_vm._s(file.name || file))]),_c('span',[_c('feather-icon',{staticStyle:{"font-weight":"800"},attrs:{"icon":"DownloadIcon"}})],1)])])}),1),_c('div',{staticClass:"d-flex justify-content-end align-items-center w-100"},[_c('span',[_c('small',[_vm._v(_vm._s(_vm._f("myGlobalDay")(msgData.time)))])]),(msgGrp.own == 1)?_c('span',{staticClass:"ml-1 d-flex align-items-center"},[_c('b-icon',{staticClass:"h4 m-0",style:({
                  color:
                    msgData.seen == 1
                      ? _vm.skin == 'dark'
                        ? '#00ff72'
                        : 'rgb(255 129 9)'
                      : '',
                }),attrs:{"icon":"check-all"}})],1):_vm._e()])],1)]})],2)])})],2)}
var staticRenderFns = []

export { render, staticRenderFns }